import React, { Component } from 'react'
import loadable from '@loadable/component'

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));
const SofttikLife = loadable(() => import("../components/softtik-life"));
const Contact = loadable(() => import("../components/contact"));
const Showcase = loadable(() => import("../components/showcase"));

const EnglishHomePage = loadable(() => import("../components/home/english.js"));
const ChineseHomePage = loadable(() => import("../components/home/chinese.js"));
const ArabicHomePage = loadable(() => import("../components/home/arabic.js"));
const CountDetails = loadable(() => import("../components/CountDetails/english.js"));
const ArabicCountDetails = loadable(() => import("../components/CountDetails/arabic.js"));
const ChineseCountDetails = loadable(() => import("../components/CountDetails/chinese.js"));
const TechnologyStack = loadable(() => import("../components/TechnologyStack/english.js"));
const ArabicTechnologyStack = loadable(() => import("../components/TechnologyStack/arabic.js"));
const ChineseTechnologyStack = loadable(() => import("../components/TechnologyStack/chinese.js"));
const Testimonials = loadable(() => import("../components/Testimonials/english.js"));
const ArabicTestimonials = loadable(() => import("../components/Testimonials/arabic.js"));
const ChineseTestimonials = loadable(() => import("../components/Testimonials/chinese"));
const FaqsHome = loadable(() => import("../components/FaqsHome/english.js"));
const ArabicFaqsHome = loadable(() => import("../components/FaqsHome/arabic.js"));
const ChineseFaqsHome = loadable(() => import("../components/FaqsHome/chinese.js"));

const schema = {
  "@context": "http://schema.org/",
  "@type": "product",
  "name": "Enterprise Blockchain Development Services & Solutions",
  "description": "Softtik provides blockchain development services with the aim of bringing revolutionary decentralized applications to reshape the technology landscape",
  "brand": "Softtik Technologies",
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.8",
    "reviewCount": "237"
  }
}

export class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lang: "English",
    }
  }


  async componentDidMount() {
    let region = localStorage.getItem('region');

    if (region == 'ae') this.setState({ lang: "Arabic" });
    // else if (region == 'sa') this.setState({ lang: "Arabic" });
    else if (region == 'cn') this.setState({ lang: "China" });
    else this.setState({ lang: "English" });

    document.onreadystatechange = function () {
      console.log('**************************document.readyState', document.readyState)
      // if (document.readyState === 'complete') setTimeout(() => setIsLoaded(true), 2000);
    }
  };

  render() {

    let { lang } = this.state;
    let { region } = this.props;

    return (
      <>
        <InnerLayout header='Main' fallback={<Loader />}>
          <Head
            type="Website"
            fallback={<Loader />}
            schemaMarkup={schema}
            path="/"
            title="Enterprise Blockchain Development Services & Solutions - Softtik"
            description="Softtik provides blockchain development services with the aim of bringing revolutionary decentralized applications to reshape the technology landscape"
          />
          <main>
            <div id="wrapper">
              <div className={`softtik-page ${lang == "Arabic" && 'rtl'}`}>
                {lang == "Arabic"
                  ? <ArabicHomePage />
                  : lang == "China"
                    ? <ChineseHomePage />
                    : <EnglishHomePage />
                }

                {/* CountDetails */}
                <section className="banner-counter">
                  {lang == "Arabic"
                    ? <ArabicCountDetails />
                    : lang == "China"
                      ? <ChineseCountDetails />
                      : <CountDetails />
                  }
                </section>

                {/* Technology */}
                <section className='technology-sec' id="technology">
                  {lang == "Arabic"
                    ? <ArabicTechnologyStack />
                    : lang == "China"
                      ? <ChineseTechnologyStack />
                      : <TechnologyStack />
                  }
                </section>

                {/* Showcase */}
                <section className='showcase-sec' id="ourwork">
                  <Showcase />
                </section>

                {/* Testimonials */}
                <section className='testimonials-sec'>
                  {lang == "Arabic"
                    ? <ArabicTestimonials />
                    : lang == "China"
                      ? <ChineseTestimonials />
                      : <Testimonials />
                  }
                </section>

                {/* Life At Softtik */}
                {/* <section className='lifesofttik-sec'>
                  <SofttikLife />
                </section> */}

                {/* Contact */}
                <section className='get-intouch-sec' id="get-intouch">
                  <Contact />
                </section>

                {/* FAQs */}
                <section id="faqs" className="faqs-sec">
                  {lang == "Arabic"
                    ? <ArabicFaqsHome />
                    : lang == "China"
                      ? <ChineseFaqsHome />
                      : <FaqsHome />
                  }
                </section >

              </div>
            </div>
          </main>
        </InnerLayout >
      </>
    )
  };
};
export default IndexPage;